import '../styles/top.scss';
import Mv from './modules/mv';
import { Scroll } from './modules/scroll';
import Nav from './modules/nav';
import cutText from './modules/cutText';
import SmoothScroll from './modules/smoothScroll';

export default class App {
    constructor() {
        window.addEventListener(
            'DOMContentLoaded',
            () => {
                this.init();
            },
            false
        );
    }
    init() {
        new Mv();
        new Scroll();
        new Nav();
        new cutText();
        new SmoothScroll();
    }
}
new App();
