export default class Nav {
    elms: {
        [s: string]: HTMLElement;
    };
    constructor() {
        this.elms = {
            body: document.querySelector('[data-body]'),
            header: document.querySelector('[data-nav="header"]'),
            btn: document.querySelector('[data-nav="btn"]'),
        };

        this.init();
    }
    init(): void {
        this.stopScroll();
        window.addEventListener('scroll',()=>{
            this.show();
        });
    }
    stopScroll(): void {
        this.elms.btn.addEventListener('click',()=>{
            if (this.elms.header.classList.contains('is-active')) {
                this.elms.body.classList.remove('is-active');
                setTimeout(()=>{
                    this.elms.header.classList.remove('is-active');
                },500)
                } else {
                this.elms.header.classList.add('is-active');
                this.elms.body.classList.add('is-active');
            }
        })
    }
    show(): void{
        let rect = this.elms.header.getBoundingClientRect();
        let scrollTop = rect.top + window.pageYOffset;

        if(scrollTop > 200){
            this.elms.header.classList.add('is-active');
        } else {
            this.elms.header.classList.remove('is-active');
        }
    }
}
