export default class cutText {
    title: NodeListOf<HTMLElement>;
    constructor() {
        this.title = document.querySelectorAll('[data-text="lead"]');
        this.init();
    }
    init(): void {
      const maxLength = 28; //文字数上限
      let modStr = '';

      this.title.forEach((targets) => {
        const str = targets.textContent;
        if (str.length > maxLength) {
          modStr = str.substr(0, maxLength) + '...';
          targets.innerHTML = modStr;
        }
      });
    }
}
