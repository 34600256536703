import throttle from 'lodash.throttle';
import { gsap } from 'gsap';

export default class Mv {
    winSize: {
        [s: string]: number;
    };
    elms: {
        [s: string]: HTMLElement;
    };
    mvText: NodeListOf<HTMLElement>;
    sp: boolean;
    ua: string;
    mq: MediaQueryList;
    constructor() {
        this.winSize = {
            wd: window.innerWidth,
            wh: window.innerHeight,
        };
        this.elms = {
            square: document.querySelector('[data-mv="square"]'),
            card: document.querySelector('[data-mv="card"]'),
            man: document.querySelector('[data-mv="man"]'),
        };
        this.mvText = document.querySelectorAll('.mv__text');
        this.sp = false;
        this.ua = window.navigator.userAgent.toLowerCase();
        this.mq = window.matchMedia('(max-width: 768px)');
        this.init();
    }
    init(): void {
        this.getLayout();
        this.handleEvents();

        if (this.ua.indexOf('msie') !== -1 || this.ua.indexOf('trident') !== -1) {
            return;
        } else {
            this.mq.addEventListener('change', this.getLayout.bind(this));
        }
        this.start();
    }
    getLayout(): void {
        this.sp = this.mq.matches ? true : false;
    }
    start(): void {
        this.animate(); // アニメーション開始
    }
    animate() {
        gsap.config({
            force3D: true,
        });
        const tl = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.6,
                ease: 'power2.easeIn',
            },
        });
        tl.to(
            this.elms.square,
            {
                x:0,
                duration: 0.3,
                opacity: 1,
            },
        );
        tl.to(
            this.mvText,
            {
                opacity: 1,
                stagger: 0.3,
                scale: 1,
                rotate:0
            },
            0.5
        );
        tl.to(
            this.elms.man,
            {
                className: 'mv__man is-active',
            },
            2.0
        );
        tl.to(
            this.elms.card,
            {
                duration: 0.4,
                opacity: 1,
            },
            2.1
        );
        tl.play();
    }
    handleEvents(): void {
        // リサイズイベント登録
        window.addEventListener(
            'resize',
            throttle(() => {
                this.handleResize();
            }, 100),
            false
        );
    }
    handleResize(): void {
        // リサイズ処理
        this.winSize = {
            wd: window.innerWidth,
            wh: window.innerHeight,
        };
    }
}
