require('intersection-observer');
import { gsap } from 'gsap';

export class Scroll {
    threshold: number | number[];
    addClass: string;
    targets: NodeListOf<HTMLElement>;

    constructor() {
        this.threshold = [0.5];
        this.addClass = 'is-active';
        this.targets = document.querySelectorAll('[data-scroll]');
        this.init();
    }
    init(): void {
        if (this.targets.length > 0) {
            this.scroll();
        }
    }
    scroll() {
        gsap.config({
            force3D: true,
        });
        const options = {
            threshold: this.threshold,
        };
        const callback = (entries: any, observer: any) => {
            entries.forEach((entry: any) => {
                if (entry.intersectionRatio >= this.threshold) {
                    this.checkType(entry.target, observer);
                }
            });
        };
        const observer = new IntersectionObserver(callback, options);
        this.targets.forEach((elm) => {
            observer.observe(elm);
        });
    }
    checkType(target: any, observer: any) {
        const type = target.getAttribute('data-scroll-type');

        if (type) {
            switch (type) {
                case 'blogItems':
                    this.blogItems();
                    if (observer) observer.unobserve(target);
                    break;
                case 'fadeIn':
                    target.classList.add(this.addClass);
                    if (observer) observer.unobserve(target);
                    break;
                default:
                    break;
            }
        } else {
            target.classList.add(this.addClass);
            if (observer) observer.unobserve(target);
        }
    }
    blogItems() {
        const pos = document.querySelectorAll('[data-blog="stagger"]');

        const tl = gsap.timeline({
            defaults: {
                ease: 'power2.ease',
            },
        });
        tl.to(
            pos,
            {
                stagger: 0.09,
                y: 0,
                opacity: 1,
            },
        );
    }
}
